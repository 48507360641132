import { getCorrelationId } from '@paybis/frontend-common-lib/src/services/correlation';
import {
  denyUrls,
  ignoredErrorMessages,
  filterUnwantedExceptions,
} from './sentry-ignore-config';

if (process.env.NODE_ENV === 'production') {
  window.sentryOnLoad = () => {
    Sentry.init({
      environment: DEPLOYMENT_ENV, // eslint-disable-line no-undef
      dsn: SENTRY_DSN, // eslint-disable-line no-undef
      maxBreadcrumbs: 10, // By default sentry stores 100 breadcrumbs
      beforeSend: filterUnwantedExceptions,
      ignoreErrors: ignoredErrorMessages,
      denyUrls,
    });

    if (Sentry.rewriteFramesIntegration) {
      Sentry.addIntegration(Sentry.rewriteFramesIntegration());
    }

    Sentry.setTags({
      correlation_id: getCorrelationId(),
    });
  }
}
